<template>
  <div class="white">
    <v-container class="pa-10">
      <v-row
        id="who_we_are"
        name="who_we_are"
        class="my-10"
        justify="center"
      >
        <v-col
          cols="12"
          md="6"
          class="my-10"
          offset-md="1"
          order-md="12"
          align-self="center"
        >
          <h6 class="primary--text text-uppercase text--heading">
            Who Are We
          </h6>
          <h1>Digital disruptors</h1>
          <p>Pink Zebra is a collective of digital specialists dedicated to disrupting brands that are stuck in the past, and championing those that are embracing a digital future.</p>
          <p>For too long, brands have been stagnant in adoption of new technologies, awaiting competitors to take the first tentative steps by which time, they have already lost their voice in a world of noise. They rely on ‘tried and tested’ methods for communication and engagement that are outdated and approved by those that still remember their landline number.</p>
        </v-col>
        <v-col
          cols="12"
          sm="8"
          md="5"
          xl="3"
          order-md="1"
          align-self="center"
        >
          <v-img
            :src="require('@/assets/images/who_we_are-01.png')"
          />
        </v-col>
      </v-row>
      <v-row
        id="what_we_do"
        name="what_we_do"
        class="my-10 fill-height"
        justify="center"
      >
        <v-col
          cols="12"
          md="6"
          class="my-10"
          align-self="center"
        >
          <h6 class="primary--text text-uppercase text--heading">
            What we do
          </h6>
          <h1>Embracing a digital future</h1>
          <p>Our purpose is to bring brands into a new age of digital connection with its consumers. One that breeds loyalty, engagement, conversation and conversion. A system of digital products that are at once universal and local, brand-centric and consumer focused – freed from the shackles of archaic thought processes that have been driven by habits developed decades ago, and which thrive on utilising our experience of the then, our understanding of the now and predictions of a better, digital future.</p>
        </v-col>
        <v-col
          cols="12"
          sm="8"
          md="5"
          xl="3"
          offset-md="1"
        >
          <v-img
            :src="require('@/assets/images/what_we_do-01.jpg')"
            height="100%"
            gradient="to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4)"
          />
        </v-col>
      </v-row>
      <v-row
        id="myy_app"
        name="myy_app"
        class="my-10"
        justify="center"
      >
        <v-col
          cols="12"
          md="6"
          class="my-10"
          order-md="12"
          offset-md="1"
          align-self="center"
        >
          <h6 class="primary--text text-uppercase text--heading">
            Myy App
          </h6>
          <h1>
            Promotions Reimagined.
            <br>Loyalty Rewarded.
          </h1>
          <p>Myy app is a service provided to you by your preferred brands to simplify and speed up your rewards and promotional programmes.</p>
          <v-btn
            outlined
            color="primary"
            tile
            class="mb-10"
            href="https://www.myyapp.com.au/"
            target="_blank"
          >
            Find Out More
          </v-btn>
          <br>
          <img
            :src="require('@/assets/images/myy_app_icon.png')"
            class="me-2"
            style="width: 80px"
          >
          <div style="display: inline-block;">
            <h5 class="mt-0 mb-1">
              Download myy app
            </h5>
            <a
              href="https://apps.apple.com/app/mii/id1442182158"
              target="_blank"
            >
              <img
                :src="require('@/assets/images/download-appstore.png')"
                class="me-2"
                style="width: 150px"
              >
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.pinkzebra.apps.miiappau"
              target="_blank"
            >
              <img
                :src="require('@/assets/images/download-google_play.png')"
                class="me-2"
                style="width: 150px"
              >
            </a>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="8"
          md="5"
          xl="3"
          order-md="1"
          align-self="center"
        >
          <v-img
            :src="require('@/assets/images/myy_app-01.png')"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>
